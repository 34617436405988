













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ChecklistElement, ElementStatus} from "@/models/checklistElement";
import {MaintenanceElementStatus, MaintenanceListElement} from "@/models/maintenanceElement";

@Component({})
export default class MaintenanceListRadioComponent extends Vue {

  @Prop({default: new MaintenanceListElement({})})
  public element!: MaintenanceListElement

  public status?: MaintenanceElementStatus | null = null;

  public radioOptions: Array<{
    status: MaintenanceElementStatus,
    text: string
  }> = [{
    status: MaintenanceElementStatus.checkOkay, text: 'i.o.'
  }, {
    status: MaintenanceElementStatus.checkNotOkay, text: 'n.i.o',
  }, {
    status: MaintenanceElementStatus.notRequired, text: 'n.v'
  }];

  @Watch('element', { immediate: true })
  public elementChanged() {
    if (this.element) {
      this.status = this.element.controlStatus;
    }
  }

  public onChange() {
    this.$emit('changed', this.status);
  }
}

